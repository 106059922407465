import { Image, useTheme } from "@aws-amplify/ui-react";
import { signInWithRedirect } from "aws-amplify/auth";
const Footer = () => {
  const { tokens } = useTheme();
  console.log(tokens);
  return (
    <div direction="column" className="federated-sign-in-container">
      <>
        <button
          onClick={async () => {
            await signInWithRedirect({
              provider: {
                custom: "OKTA",
              },
            });
          }}
          className="federated-sign-in-button"
          gap="1rem"
        >
          <img src="sso-icon.png" alt="SSO Logo" />
          <div as="span" style={{ color: "white !important" }}>
            Single Sign-On
          </div>
        </button>
      </>
    </div>
  );
};

export default Footer;
