// replace the user pool region, id, and app client id details
export default {
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_AWS_USERPOOL_APP_CLIENT_ID,
      userPoolId: process.env.REACT_APP_AWS_USERPOOL_ID,
      loginWith: {
        oauth: {
          provider: "OKTA",
          domain: "dev-canvas-ai.auth.us-east-2.amazoncognito.com",
          scopes: ["email", "openid"],
          redirectSignIn: [
            "https://canvasai.criticalmass.com/",
            "https://canvasai.criticalmass.com/",
          ],
          redirectSignOut: [
            "https://canvasai.criticalmass.com/logout",
          ],
          responseType: "code",
        },
        username: "true",
      },
    },
  },
};
